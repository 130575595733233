<template>
  <v-container v-if="microservice" fluid>
    <RouterView :microservice="microservice" />
  </v-container>
</template>

<script>
import { show } from "@/api/endpoints/microservice.js";
import Microservice from "@/application/models/microservice.js";

export default {
  name: "MicroserviceLayoutWrapper",
  data() {
    return {
      microservice: null,
    };
  },
  watch: {
    "$route.params.clientId": {
      immediate: true,
      handler() {
        this.getMicroservice();
      },
    },
  },
  methods: {
    async getMicroservice() {
      const response = await show(this.$route.params.microserviceId);
      this.microservice = new Microservice().mapResponse(response.data.data);
    },
  },
};
</script>
