import Model from "@/application/models/model.js";

class MicroService extends Model {
  id = 0;
  name = "";
  clientName = "";
  isActive = true;
  version = null;

  mapForRequest() {
    return {
      name: this.name,
      clientName: this.clientName,
      isActive: this.isActive,
      version: this.version,
    };
  }
}

export default MicroService;
